.App {
  text-align: center;
}
main {
  margin-top: 6px;
  div:nth-child(2) {
    padding-top: 0px;
    padding-right: 4px;
    > div {
      > div {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

div[role="tooltip"] {
  z-index: 9999;
}
p.Mui-error {
  // position: absolute;
  bottom: -2em;
}

.layout > :first-child {
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .sidebar > :nth-child(3) {
    background-color: #1565c0;
  }
}

@media only screen and (min-width: 600px) {
  .sidebar {
    background-color: #1565c0;
  }

  .sidebar :nth-child(3) {
    background-color: none;
  }
}

/* SIZE BAR BLOCK */

.sidebar a {
  color: #fff;
  font-size: 14px;
}

.sidebar div {
  color: #fff;
  font-size: 14px;
}

.sidebar span {
  color: #fff;
  font-size: 14px;
}

/* APP BAR */
.MuiAppBar-colorSecondary {
  color: #fff;
  background-color: #0d47a1 !important;
}

.top-toolbar {
  padding-top: 16px !important;
}

/** TABLE */
.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.MuiTable-root {
  padding: 0 10px;
}

th.MuiTableCell-root,
td.MuiTableCell-root:not(.MuiTableCell-footer) {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray !important;
}

#bill-form
  > div
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters:not(.top-toolbar) {
  padding: 0;
}

.MuiTableRow-root:empty {
  display: none;
}

$PRIMARY_COLOR: #1565c0;
$COLOR_WHITE: #fff;

th.MuiTableCell-head {
  background-color: $PRIMARY_COLOR;
  text-align: center;
  color: #fff;

  span,
  input,
  svg {
    color: $COLOR_WHITE !important;
  }
}

.MuiToolbar-root.MuiToolbar-regular[role="toolbar"] {
  justify-content: space-between;
}

// EWORKING
#typo-h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#bill-form div:nth-child(1) > h6 {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

// .MuiDrawer-paper {
//   width: 290px !important;
// }

body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  div.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  padding: 0px !important;
}

// .MuiCollapse-wrapperInner > div {
//   padding-left: 24px !important;
// }

// popup bill
.MuiAutocomplete-popper {
  min-width: 240px !important;
}

.MuiTableCell-paddingCheckbox:last-child {
  min-width: 135px !important;
}

// list page

.RaFilterFormInput-spacer-109 {
  width: 4px !important;
}

.checkbox-mt-6 {
  margin-top: -6px;
}

.edit-page,
.create-page {
  > div:first-child {
    min-height: unset !important;
    padding: 0px !important;
  }

  > div:nth-child(2) {
    margin-top: 0px;
  }
  > div {
    padding: 0px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: start;

    > button,
    > span[role="button"],
    > a {
      color: #fff;
      background-color: #1565c0;
      border-radius: 0px;
      margin-right: 4px;
      text-transform: unset;

      &:hover {
        background-color: #1565c0;
      }
    }
  }
}
.list-page {
  > div:first-child {
    > div {
      min-height: unset !important;
      fieldset {
        display: none;
      }
    }
  }
  .filter-fieldset {
    margin-top: 10px;
    width: -webkit-fill-available;
    border-color: #eee;
  }

  table > tbody > tr {
    > td.column-createdAt {
      padding: 4px 16px;
      text-align: center;
      > p {
        padding: 0;
        margin: 0;
      }
    }
  }
  > div {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:last-child {
      order: -1;
      padding: 0;
      margin-top: 10px;
      > button,
      > span[role="button"],
      > div > button,
      > a {
        color: #fff;
        background-color: #1565c0;
        border-radius: 0px;
        margin-right: 2px;
      }
    }
    > div:last-child:empty {
      display: none;
    }
    > span:last-child:empty {
      display: none;
    }
  }
  form {
    padding: 0;
    margin: 0;
    min-height: 0px;
    .MuiOutlinedInput-root {
      height: 32px;
    }
    .MuiInputLabel-root {
      font-size: 0.9rem;
      top: -2px;
    }
  }
}

button.page-number {
  min-width: 24px;
}

.MuiButton-textSizeSmall {
  padding: 4px 6px !important;
  font-size: 12px !important;
  svg {
    font-size: 18px !important;
  }
}

.text--strong {
  font-weight: 600;
}
