.block-upload-image {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eee;
  padding: 12px;
  cursor: pointer;
}

.block-upload-image-preview {
  width: 100px;
  height: 60px;
  object-fit: cover;
}

.block-upload-file {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eee;
  padding: 4px;
  cursor: pointer;
}

.block-upload-file-preview {
  width: 70px;
  object-fit: cover;
}
