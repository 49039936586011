.wrapper {
  margin-top: 12px;
  fieldset {
    padding-left: 0px;
    padding-right: 0px;
    border: none;
  }
}
.bill-chart {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .header {
    text-align: center;
  }
}

#bill {
  width: 100%;
}

#supplyrelativebills {
  width: 100%;
}

.acc_title {
  margin: 0px;
}
.dashboard_legend {
  font-weight: 600;
  font-size: 1.17em;
}
