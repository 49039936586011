div.previews {
  max-height: 300px;
  overflow: auto;
}

div.previews > div {
  margin: 2px;
  border: 1px solid #ccc;
}

div.previews > div:hover {
  border: 1px solid #777;
}

div.hidden-delete button {
  display: none;
}

div.previews > div img {
  // width: 100%;
  object-fit: cover;
  height: auto;
  width: 160px;
  height: 100px;
}

.form-dialog-content {
  display: flex;
  flex-direction: row;
  height: 100vh;
  .close {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    text-justify: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    font-weight: bold;
    background-color: #ccc;
    line-height: 0px;
    margin: 8px 8px;
    cursor: pointer;
    &:hover {
      background-color: #fff;
    }
  }

  .form-dialog-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #777;
    img {
      max-width: 100%;
      max-height: 100vh;
      object-fit: cover;
      display: block;
    }
  }

  .form-dialog-comment {
    width: 350px;
    position: relative;

    .comment-input {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      background-color: #ccc;
      padding-top: 10px;
      .input-wrapper {
        flex: 1;
        margin: 0 0 10px 10px;
        .input {
          width: 100%;
          height: 20px;
        }
      }

      .send {
        margin: 0 10px 0 20px;
      }
    }

    .comment-list {
      height: 100vh;
      overflow-y: auto;
      .comment-empty {
        font-weight: 650;
        margin: 8px 8px;
      }
      .comment-end {
        padding-top: 50px;
      }
      .comment-item {
        &:first-child {
          padding-top: 8px;
        }

        display: flex;
        padding-top: 4px;
        .user-avatar {
          width: 40px;
          margin-left: 8px;
        }

        .comment-content {
          .comment-content-background {
            border-radius: 15px;
            background-color: #e4e6eb;
            padding: 8px 8px;
            margin: 0 8px;
            .user-name {
              font-weight: 600;
              font-size: 0.8125rem;
              line-height: 1.2308;
            }

            .content {
              font-size: 0.9375rem;
              // display: inline;
              word-break: break-all;

              // word-wrap: break-word;
            }
          }

          .time {
            padding: 4px 12px;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            color: #777;
          }
        }
      }
    }
  }
}
