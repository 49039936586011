#supplyrelativebills {
  table tr {
    td:first-child {
      min-width: 100px;
    }

    td:nth-child(2) {
      min-width: 100px;
      max-width: 150px;
      word-break: break-all;
    }
    td:nth-child(3) {
      min-width: 150px;
      word-break: break-all;
    }
    td:nth-child(4) {
      min-width: 350px;
      max-width: 350px;
      word-break: break-all;
    }

    td:nth-child(5) {
      width: 50px;
    }

    td:nth-child(6) {
      width: 100px;
    }

    td:nth-child(7) {
      width: 100px;
    }

    td:nth-child(8) {
      width: 100px;
    }
  }
}

#supplies {
  .btn-more {
    background-color: #eee;
    border-radius: 4px;
    padding: 4px 8px;
  }

  table tr {
    td:last-child {
      min-width: 100px;
      max-width: 150px;
    }

    td:nth-last-child(2) {
      width: 30px;
    }

    td:nth-last-child(3) {
      width: 30px;
    }
    td:nth-last-child(4) {
      width: 30px;
    }

    td:nth-last-child(5) {
      min-width: 200px;
      max-width: 200px;
      word-break: break-all;
    }

    td:nth-last-child(6) {
      min-width: 70px;
      max-width: 100px;
      word-break: break-all;
    }

    td:nth-last-child(7) {
      width: 100px;
      word-break: break-all;
    }

    td:nth-last-child(8) {
      width: 100px;
      word-break: break-all;
    }

    td:nth-last-child(9) {
      width: 100px;
      word-wrap: break-word;
    }

    td:nth-last-child(10) {
      width: 100px;
      word-break: break-all;
    }
  }
}
